import { Config as ConfigType } from './types';
import { user } from './sopra/user';
import { expert } from './sopra/expert';
import { client } from './sopra/client';
import { legalEntity } from './sopra/legalEntity';
import { company } from './sopra/company';
import { mission } from './sopra/mission';
import { invitation } from './sopra/invitation';
import { candidacy } from './sopra/candidacy';
import { cgu } from './sopra/cgu';
import { match } from './sopra/match';
import { document } from './sopra/document';
import { subCategory } from './sopra/subCategory';
import { documentFile } from './sopra/documentFile';
import { documentsCompanies } from './sopra/documentsCompanies';
import { skillsCartography } from './sopra/skillsCartography';
import { tag } from './sopra/tag';

export const config: ConfigType = {
  login: 'password',
  hash: '4rr0tdosatvzb70almrhxtkbk',
  locale: 'fr',
  name: 'sopra',
  dashboard: true,
  theme: {
    primary: '#0C073C',
    secondary: '#364CBB',
    info: '#f8dddd',
  },
  defaultCountry: 'fr',
  preferredCountries: ['fr', 'ma', 'ch', 'tn'],
  resourcesAccess: true,
  resources: [
    expert,
    client,
    user,
    legalEntity,
    subCategory,
    company,
    mission,
    invitation,
    cgu,
    match,
    tag,
    skillsCartography,
  ],
};

export default config;
