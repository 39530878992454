import { Resource as ResourceType } from '../types';

export const legalEntity: ResourceType = {
  name: 'legal-entities',
  label: 'resource.clients.label',
  hideResource: true,
  attributes: [
    {
      key: 'name',
      label: 'resource.legal-entities.field.name.label',
      type: 'text',
    },
  ],
};
